import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Suspense, useState, useEffect, lazy } from 'react';
import { useSelector } from 'react-redux';

import { getCampaignInfo, setUserAbTests, stripeCheckoutSession } from '@jaramba-frontend/core/api';
import { ABTests } from '@jaramba-frontend/core/constants';
import { CookieService } from '@jaramba-frontend/core/services';
import { BillingIntervals, Campaigns } from '@jaramba-frontend/core/types';

import {
    applyOffer,
    cancelStripeSubscription,
    cancelSwedbankSubscription,
    getChurnOffer,
    stripeSubscriptionManagementSession,
} from '../../api';
import { sendAnalyticsEvent } from '../../api/analytics';
import { routes, TOAST_MESSAGES } from '../../constants';
import { clearProduct, setProduct } from '../../store/features/product/productSlice';
import { setSubscriptionInfo } from '../../store/features/user/userSlice';
import { useAppDispatch } from '../../store/hooks';
import type { RootState } from '../../store/store';
import { ChurnOfferInfo, SubscriptionStatuses } from '../../types';
import {
    cancelSubscriptionButtonClickEvent,
    cancelWarningModalEvent,
    churnOfferEvent,
    manageSubscriptionButtonClickEvent,
    subscriptionCancelledEvent,
    trialCancelWarningModalEvent,
} from '../../types/analyticsEvents';
import { Toast } from '../../utils';

const SubscriptionInfo = lazy(() => import('../SubscriptionInfo'));
const Loading = lazy(() => import('@jaramba-frontend/core/components/Loading'));
const LoadingScreen = lazy(() => import('../LoadingScreen'));
const Products = lazy(() => import('../Products'));
const CancelSwedbankSubscriptionModal = lazy(() => import('../CancelSwedbankSubscriptionModal'));
const ChurnOffer = lazy(() => import('../ChurnOffer'));
const CancelWarningModal = lazy(() => import('../CancelWarningModal'));

interface Props {
    onUpdateUserInfo: () => Promise<void>;
}

const Subscription = ({ onUpdateUserInfo }: Props) => {
    const dispatch = useAppDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const { product } = useSelector((state: RootState) => state.product);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isSwedbankCancellationModalOpen, setSwedbankCancellationModalOpen] = useState<boolean>(false);
    const [isCancelWarningModalOpen, setCancelWarningModalOpen] = useState<boolean>(false);
    const [isOfferOpen, setOfferOpen] = useState<boolean>(false);
    const [churnOfferInfo, setChurnOfferInfo] = useState<ChurnOfferInfo>();
    const [trialCancelWarningModalExperimentValue, setTrialCancelWarningModalExperimentValue] = useState<number | null>(
        null
    );

    const hasActiveSubscription = user?.userInfo?.ProductKind === SubscriptionStatuses.ActiveSubscription;
    const hasCancelledSubscription = user?.userInfo?.ProductKind === SubscriptionStatuses.CancelledSubscription;
    const hasNoSubscription = user?.userInfo?.ProductKind === SubscriptionStatuses.NoSubscription;
    const hasDiscount = !!user?.subscriptionInfo?.discountApplied;
    const isInTrial = user?.subscriptionInfo?.status === 'Trialing';
    const billingInterval = user?.subscriptionInfo?.billingInterval?.toLowerCase() as BillingIntervals;
    const storedCampaignName = sessionStorage.getItem('campaignName') ?? undefined;
    const subscriptionInfoEventProperties = {
        subscription_status: user?.subscriptionInfo?.status?.toLowerCase() as SubscriptionStatuses,
        has_discount: hasDiscount,
        in_trial: isInTrial,
        subscription_period: billingInterval,
    };
    const churnOfferEventProperties = {
        name: churnOfferInfo?.name ?? '',
        coupon_id: churnOfferInfo?.couponId ?? '',
        in_trial: isInTrial,
        discount_type: churnOfferInfo?.name ?? '',
        subscription_period: billingInterval,
    };
    const daysAfterRegistration = dayjs().diff(dayjs(user?.subscriptionInfo?.created), 'days');

    // HACK: Due to limitations in the available subscription data for some older Apple users,
    // we are using a workaround to determine if the user has an active subscription.
    // This workaround relies on checking if the next payment date has expired,
    // with an added grace period for Apple subscriptions.
    // This approach may not be accurate in all cases and should be revisited.
    let hasExpiredPayment = false;

    // Grace period durations are dependent on the subscription period length as follows:
    // 6 days for a weekly subscription and 16 days for monthly and longer subscriptions.
    // The maximum timezone difference can be more than 24 hours and 18 days will definitely cover it
    const gracePeriodDays = 18;

    if (user && user.userInfo) {
        hasExpiredPayment = dayjs(user.userInfo.NextPayment + 'T00:00:00.000Z')
            .add(gracePeriodDays, 'day')
            .isBefore(dayjs());
    }

    const fetchCampaignInfo = async () => {
        try {
            const { prices, campaign } = await getCampaignInfo(
                storedCampaignName || (hasCancelledSubscription ? Campaigns.Reactivate : '')
            );

            dispatch(
                setProduct({
                    campaign,
                    prices,
                })
            );
        } catch (err) {
            console.error(err);

            if (err instanceof AxiosError) {
                if (err.response?.status === 404) {
                    await fetchCampaignInfo();
                } else {
                    Toast.error(TOAST_MESSAGES.commonError);
                    throw new Error('Failed to fetch products');
                }
            }
        }
    };

    useEffect(() => {
        /** Fetch campaign in cases of:
            - No active subscription and no product
            - Cancelled subscription and campaign is not Reactivate
            - Expired payment
            - Campaign name has changed (for users who got email with campaign name in URL)
        */
        if (
            (!hasActiveSubscription && !product) ||
            (hasCancelledSubscription && product?.campaign.name !== Campaigns.Reactivate) ||
            hasExpiredPayment ||
            (storedCampaignName && product?.campaign && product.campaign.name !== storedCampaignName)
        ) {
            fetchCampaignInfo();
        }
    }, [user?.userInfo?.ProductKind]);

    const handleToggleSwedbankSubscriptionCancelModal = (open: boolean) => {
        setSwedbankCancellationModalOpen(open);
    };

    const handleCancelSwedbankSubscription = async () => {
        try {
            setLoading(true);

            await cancelSwedbankSubscription();
            await onUpdateUserInfo();
        } catch (err) {
            console.error(err);
            setErrorMessage('Det gick inte att säga upp Swedbank Pay-prenumeration. Försök igen senare.');
            Toast.error(TOAST_MESSAGES.commonError);
            throw new Error('Failed to cancel Swedbank Pay subscription');
        } finally {
            setLoading(false);
            setSwedbankCancellationModalOpen(false);
            Toast.error(TOAST_MESSAGES.commonError);
        }
    };

    const clearProductAndRedirect = (redirectUrl?: string) => {
        if (redirectUrl) {
            dispatch(clearProduct());
            window.location.href = redirectUrl;
        } else {
            Toast.error(TOAST_MESSAGES.commonError);
            throw new Error('No redirect url');
        }
    };

    const handleRedirectToStripeSubscriptionManagement = async () => {
        try {
            setLoadingScreen(true);
            const redirectUrl = await stripeSubscriptionManagementSession();
            await sendAnalyticsEvent(manageSubscriptionButtonClickEvent(subscriptionInfoEventProperties));
            clearProductAndRedirect(redirectUrl);
        } catch {
            Toast.error(TOAST_MESSAGES.commonError);
            setLoadingScreen(false);
        }
    };

    const handleSelectPlanClick = async (billingInterval: BillingIntervals) => {
        try {
            setLoadingScreen(true);

            const redirectUrl = await stripeCheckoutSession({
                token: user?.token ?? '',
                successUrl: `${window.location.origin}${routes.DOWNLOAD}`,
                cancelUrl: `${window.location.origin}${routes.HOME}`,
                priceLookupKey: product?.prices.find((price) => price.billingInterval.toLowerCase() === billingInterval)
                    ?.key as string,
                campaignId: product?.campaign.name,
            });

            clearProductAndRedirect(redirectUrl);
        } catch (err) {
            setLoadingScreen(false);
            console.error(err);
            setErrorMessage('Det gick inte att begära omdirigering till Stripe. Försök igen senare.');
            Toast.error(TOAST_MESSAGES.commonError);
            throw new Error('Failed to request redirect to Stripe');
        }
    };

    const cancelSubscription = async () => {
        if (isOfferOpen) {
            setOfferOpen(false);
        }

        try {
            setLoading(true);
            await cancelStripeSubscription();
            dispatch(setSubscriptionInfo({ ...user?.subscriptionInfo!, autoRenewalEnabled: false }));
            Toast.success(TOAST_MESSAGES.subscriptionCanceled);
            sendAnalyticsEvent(subscriptionCancelledEvent(subscriptionInfoEventProperties));
        } catch (err) {
            Toast.error(TOAST_MESSAGES.commonError);
            throw new Error('Failed to cancel subscription');
        } finally {
            setLoading(false);
            setCancelWarningModalOpen(false);
        }
    };

    const handleCancelSubscriptionClick = async () => {
        sendAnalyticsEvent(
            cancelSubscriptionButtonClickEvent({
                has_discount: hasDiscount,
                in_trial: isInTrial,
                subscription_period: billingInterval,
            })
        );

        if (hasDiscount) {
            setCancelWarningModalOpen(true);
        } else {
            await handleOpenChurnOffer();
        }
    };

    const handleOpenChurnOffer = async () => {
        try {
            setLoading(true);

            const churnOffer = await getChurnOffer(billingInterval, isInTrial ? 'trial' : 'paying');

            if (churnOffer) {
                setOfferOpen(true);
                setChurnOfferInfo(churnOffer);
            } else {
                throw new Error();
            }
        } catch {
            setCancelWarningModalOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleAcceptOffer = async () => {
        try {
            setLoading(true);
            await applyOffer(churnOfferInfo?.name as string);

            setOfferOpen(false);
            Toast.success(TOAST_MESSAGES.offerApplied);

            sendAnalyticsEvent(
                churnOfferEvent({
                    discount_status: 'discount_accepted',
                    ...churnOfferEventProperties,
                })
            );
            await onUpdateUserInfo();
        } catch (error) {
            console.error(error);
            Toast.error(TOAST_MESSAGES.commonError);
            throw new Error('Failed to accept churn offer');
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmCancel = () => {
        cancelSubscription();

        if (typeof trialCancelWarningModalExperimentValue === 'number' && isInTrial) {
            sendAnalyticsEvent(
                trialCancelWarningModalEvent({
                    action: 'cancelation_continued',
                    days_after_registration: daysAfterRegistration,
                })
            );
        } else {
            sendAnalyticsEvent(cancelWarningModalEvent({ action: 'cancellation_continued' }));
        }
    };

    const handleAbortCancel = () => {
        if (typeof trialCancelWarningModalExperimentValue === 'number' && isInTrial) {
            sendAnalyticsEvent(
                trialCancelWarningModalEvent({
                    action: 'cancelation_aborted',
                    days_after_registration: daysAfterRegistration,
                })
            );
        } else {
            sendAnalyticsEvent(cancelWarningModalEvent({ action: 'cancellation_aborted' }));
        }

        setCancelWarningModalOpen(false);
    };

    useEffect(() => {
        const abTestId = ABTests.TrialCancelWarningModal.id;

        if (isInTrial) {
            if (!CookieService.get(abTestId)) {
                (async () => {
                    try {
                        const experimentValue = Math.round(Math.random());
                        CookieService.set(abTestId, experimentValue, {
                            path: '/',
                            expires: dayjs().add(6, 'month').toDate(),
                        });
                        setTrialCancelWarningModalExperimentValue(experimentValue);

                        setUserAbTests(
                            [
                                {
                                    Name: ABTests.TrialCancelWarningModal.mixpanelId,
                                    Variant: experimentValue,
                                },
                            ],
                            user?.token as string
                        ).catch(() => null);
                    } catch {
                        setTrialCancelWarningModalExperimentValue(0);
                    }
                })();
            } else {
                setTrialCancelWarningModalExperimentValue(Number(CookieService.get(abTestId)));
            }
        }
    }, [isInTrial]);

    if (loadingScreen) {
        return (
            <Suspense fallback={null}>
                <LoadingScreen />
            </Suspense>
        );
    }

    if (loading) {
        return (
            <Suspense fallback={null}>
                <Loading />
            </Suspense>
        );
    }

    return (
        <>
            {hasActiveSubscription && !hasExpiredPayment && !isOfferOpen && (
                <Suspense fallback={null}>
                    <SubscriptionInfo
                        loading={loading || loadingScreen}
                        errorMessage={errorMessage}
                        onToggleSwedbankSubscriptionCancelModal={handleToggleSwedbankSubscriptionCancelModal}
                        onManageSubscriptionClick={handleRedirectToStripeSubscriptionManagement}
                        onCancelSubscriptionClick={handleCancelSubscriptionClick}
                    />
                </Suspense>
            )}

            {(hasNoSubscription || hasCancelledSubscription || hasExpiredPayment) && (
                <Suspense fallback={null}>
                    <Products onSelectPlanClick={handleSelectPlanClick} />
                </Suspense>
            )}

            {isOfferOpen && !loading && (
                <Suspense fallback={null}>
                    <ChurnOffer
                        title={churnOfferInfo?.title ?? ''}
                        description={churnOfferInfo?.description ?? ''}
                        onAcceptOfferClick={handleAcceptOffer}
                        onCancelClick={() => setCancelWarningModalOpen(true)}
                    />
                </Suspense>
            )}

            {isSwedbankCancellationModalOpen && (
                <Suspense fallback={null}>
                    <CancelSwedbankSubscriptionModal
                        isOpen
                        onAccept={handleCancelSwedbankSubscription}
                        onClose={() => handleToggleSwedbankSubscriptionCancelModal(false)}
                    />
                </Suspense>
            )}

            {isCancelWarningModalOpen && (
                <Suspense fallback={null}>
                    <CancelWarningModal
                        isTrialUsersAbTest={!!trialCancelWarningModalExperimentValue}
                        onConfirm={handleConfirmCancel}
                        onClose={handleAbortCancel}
                    />
                </Suspense>
            )}
        </>
    );
};

export default Subscription;
