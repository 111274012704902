import { CookieService, SharedApiService } from '@jaramba-frontend/core/services';

import { routes } from '../constants';
import { getStoreState } from '../store/store';

const getJarambaClient = async () => {
    const token = getStoreState().user.user?.token;

    return {
        'jaramba-client': process.env.REACT_APP_JARAMBA_CLIENT,
        ...(token && { Authorization: `Bearer ${token}` }),
    };
};

const signOut = async () => {
    CookieService.remove('auth');
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = routes.LOGIN;
    return;
};

export const apiService = new SharedApiService(
    [getJarambaClient],
    getStoreState().user.user?.token ? signOut : undefined
);

export * from './auth';
export * from './subscription';
export * from './user';
export * from './churnOffer';
